export const dropdownsData = (
    checkboxFiltersRadio,
    setCheckboxFiltersRadio
) => {
    return ([
    {
        type: 'checkbox',
        title: 'Infrastructure',
        id: 'infrastructure',
        elements: [
            {
                text: 'Landmarks',
                id: 'landmarks'
            },
            {
                text: 'Transport (Metro)',
                id: 'transport'
            },
            {
                text: 'Education',
                id: 'Education'
            },
            {
                text: 'Health',
                id: 'Health'
            },
            {
                text: 'Retail & Attractions',
                id: 'Retail & Attractions'
            }
        ],
        stateObject: {
            state: checkboxFiltersRadio,
            setState: setCheckboxFiltersRadio
        }
    }
    ])
}