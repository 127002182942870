import React from "react";
import { useState, useEffect, useRef } from "react";
import { useSwipeable } from "react-swipeable";

import { styled } from "styled-components";

import './Card.scss'

import ExitCross from '../../../static/images/card-exit-cross.svg'
import ButtonArrow from '../../../static/images/card-arrow.svg'
import CardQR from '../../../static/images/nakheel-qr.png'
import AziziLogo from '../../../static/images/azizi-logo-bg.png'

import { WindowCardGallery } from "../window-card/WindowCard";

const CircleDiv = styled.div`
    background-color: ${({status}) => status ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.5)"};
`

function CardTopCircles({ previews, setImageIndex, activeCircle, setActiveCircle }) {
    return (
        <div className="card-top-circles">
            {
                previews.map((preview) => (
                    <CircleDiv status={activeCircle === preview} className="card-top-circle" onClick={() => {
                        setActiveCircle(preview)
                        setImageIndex(previews.indexOf(preview))
                    }}></CircleDiv>
                ))
            }
        </div>
    )
}

function CardText({ text, id }) {
    return (
        <div className={"card-" + id}>
            <span  className={"card-" + id + "-text"} dangerouslySetInnerHTML={{ __html: text }} />
        </div>
    )
}


const CardButtonDiv = styled.div`
    max-width: ${({width}) => width ? width : '48%'};
`

const CardButtonInnerDiv = styled.div`
    max-width: ${({width}) => width ? width : "calc(100% - 190px)"};
`

const CardButtonImage = styled.img`
    width: ${({width}) => width};
` 

function CardBottomButton({ text, width, openWindow, content, image, imgSize, innerWidth }) {
    return (
        <CardButtonDiv
            className="card-bottom-button"
            width={width} 
            onClick={() => {
                openWindow(content)
            }}
         >
            <CardButtonInnerDiv width={innerWidth} className="card-bottom-button-inner">
                <span className="card-bottom-button-text">{text}</span>
                <CardButtonImage 
                    src={(image ? image : ButtonArrow)} 
                    alt="button-arrow" 

                    width={(imgSize ? imgSize : '12px')}
                />
            </CardButtonInnerDiv>
        </CardButtonDiv>
    )
}


const CardPDFMessageDiv = styled.div`
    max-width: 90%;

    margin: 0 auto;
    margin-top: 65px;

    color: #000000;
    line-height: 145%;
`

function CardPDFMessage({ PDFLink }) {
    return (
        <CardPDFMessageDiv>
            <p>This browser does not support inline PDFs.</p>
            <p>Please download the PDF to view it: <a href={PDFLink} target="_blank" rel="noopener noreferrer">Download PDF</a></p>
        </CardPDFMessageDiv>
    )
}


const CardDiv = styled.div`
    opacity: ${({status}) => status ? '1' : '0'};
    pointer-events: ${({status}) => status ? 'all' : 'none'};
    transform: ${({status}) => status ? 'translateY(0)' : 'translateY(50px)'};
`

const CardTopDiv = styled.div`
    background-image: url(${({image}) => image});
`

export default function Card({ 
    activeCard, 
    setActiveCard, 
    cardContent, 
    activeWindow,
    setActiveWindow, 
    setWindowContent
}) {

    // Videos
    const videoRef = useRef(null)

    // PDF's
    function checkForMobile(PDFLink) {
        return (
            !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? (
                <iframe src={PDFLink} width="100%" height="100%" title="PDF Viewer" />
            ) : (
                <CardPDFMessage PDFLink={PDFLink} />
            )
        )
    }    

    // Images
    const [activeImage, setActiveImage] = useState()
    const [imageIndex, setImageIndex] = useState(0)
    const [activeCircle, setActiveCircle] = useState()
    useEffect(() => {
        if (cardContent.previews.length === 0) {
            setActiveImage(AziziLogo)
        } else {
            setActiveImage(cardContent.previews[0])
        }
        setActiveCircle(cardContent.previews[0])
    }, [cardContent])


    useEffect(() => {
        let image = cardContent.previews[imageIndex]
        setActiveImage(image)
        setActiveCircle(image)
    }, [imageIndex])


    const changeImage = (action) => {
        if (!(cardContent.previews.length === 0)) {
            let nextIndex = imageIndex+action
            if (nextIndex > cardContent.previews.length-1) {
                setImageIndex(0)
            } else if (nextIndex < 0) {
                setImageIndex(cardContent.previews.length-1)
            } else {
                setImageIndex(nextIndex)
            }
        }
    }

    const openWindow = (content) => {
        if (content) {
            setActiveCard(false)
            setWindowContent(content)
            setActiveWindow(true)
        }
    }

    useEffect(() => {
        try {
            videoRef.current.pause()
        } catch {}
    }, [activeWindow])
    
    
    // Swipe handlers
    const handlers = useSwipeable({
        onSwipedLeft: () => changeImage(1),
        onSwipedRight: () => changeImage(-1),
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        preventDefaultTouchmoveEvent: true,
        trackMouse: false
    })


    return (
        <div className="card">
            <CardDiv status={activeCard} className="card-container">
                <div className="card-exit" onClick={() => (
                    setActiveCard(null)
                )}>
                    <img src={ExitCross} alt="exit-cross" />
                </div>
                {/* <div className="card-qr">
                    <img src={CardQR} alt="card-qr" />
                </div> */}
                <CardTopDiv 
                    className="card-top" 
                    image={activeImage} 
                    {...handlers}
                />
                <div className="card-bottom">
                    <CardText text={cardContent.title} id="title" />
                    <CardText text={cardContent.desc} id="desc" />
                    {
                        cardContent.paymentPlan ? (
                            <CardBottomButton 
                                text="DISCOVER" 
                                openWindow={() => window.open(cardContent.paymentPlan, '_blank')} 
                                content={false}
                                width="100%" 
                            />
                        ) : cardContent.buttons && (
                            cardContent.buttons.map(({label, link, image, imgSize, innerWidth}) => (
                                <CardBottomButton 
                                    text={label} 
                                    openWindow={() => window.open(link, '_blank')} 
                                    content={false}
                                    width="100%" 
                                    image={image}
                                    imgSize={imgSize}
                                    innerWidth={innerWidth}
                                />
                            ))
                        )
                    }
                </div>
            </CardDiv>
        </div>
        
    )
}