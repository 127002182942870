export const hospitals = [
    [25.0148219557308, 55.088385625931465],
	[25.097027706287186, 55.1831018670622],
	[25.106000693816178, 55.180479955951704],
	[25.113855381725045, 55.25505124756539],
	[25.147334218068657, 55.22359271789719],
	[25.166156781480908, 55.21174697722989],
	[25.183328493999674, 55.242430337894056],
	[25.197002610615712, 55.23693549470618],
	[25.22992048971568, 55.26989551200257],
	[25.25227717543671, 55.28912210896583],
	[25.25786360925508, 55.306290827283895],
	[25.24512812400426, 55.31967696463712],
	[25.236124776563813, 55.31315054750158],
	[25.232086357272735, 55.31795534848457],
	[25.223080844410113, 55.31795204778509],
	[25.223697905547, 55.328594006167265],
	[25.250085801060813, 55.34577469255099],
	[25.25013339766853, 55.34561869799576],
	[25.283066340101485, 55.31770755406512],
	[25.285480905501696, 55.32134945768396],
	[25.29030001350838, 55.36942569262369],
	[25.290733028430896, 55.383996083997516],
	[25.02552122594179, 55.152786514360756],
	[25.036331176591002, 55.14780833452304],
	[25.02521013412843, 55.13939692721107],
	[25.050440044672825, 55.12946843731359],
	[24.879348649407888, 55.15873823230258],
	[24.888247405595443, 55.15903325288266],
	[24.886551725980336, 55.160368066808985],
	[24.861290694558253, 55.14410825204162],
	[24.90907005031074, 55.11857213741392],
	[24.958184705436842, 55.05679136707568],
	[24.9449162978535, 55.081893451165584],
	[25.01435396594579, 55.10144206905109],
	[24.976652321897394, 55.13462925755438],
	[24.984894106639533, 55.0705279746256],
]

export const schools = [
	[25.079078145190103, 55.17889168651261],
	[25.099301309165988, 55.18229698467669],
	[25.13622789868963, 55.195424029064355],
	[25.133585954860926, 55.20513602725449],
	[25.125230552513084, 55.20901627389478],
	[25.107642857014635, 55.211433562380236],
	[25.079056355570994, 55.225495436613315],
	[25.06322641495358, 55.22742651956352],
	[25.152041894556874, 55.2486942424881],
	[25.16633052330067, 55.25899427689173],
	[25.173653967076625, 55.25905590180232],
	[25.178805769291728, 55.30334841846024],
	[25.14479729443994, 55.376639742109504],
	[25.08992110406636, 55.383519631039555],
	[25.245722207703235, 55.287488328878595],
	[25.237881669231886, 55.31635414198942],
	[25.208107240106383, 55.33250479889247],
	[25.211737333064526, 55.37349398715253],
	[25.278081258322732, 55.34524923519979],
	[25.28120348670222, 55.364890300136516],
	[25.282220656393996, 55.401284825185655],
	[25.23882985098508, 55.44051038512479],
	[25.03073903973591, 55.15135620649342],
	[25.02599500560883, 55.140112386515156],
	[25.020939685454284, 55.1400265558283],
	[25.022650740201218, 55.12989853477918],
	[25.031516732748997, 55.11427734963515],
	[25.04128064671024, 55.12009701061288],
	[24.909514964113338, 55.11834375744784],
	[24.911106240522475, 55.11719951785281],
	[24.914978983818937, 55.12449561186501],
	[24.882622706561843, 55.15297871590825],
	[24.866668682679915, 55.15574895957421],
	[24.98625889755341, 55.04366254282919],
	[24.96322861723136, 55.06391858492743],
	[25.009440430903503, 55.08400296565195],
	[24.97847887973309, 55.08228635191481],
	[24.94247630385953, 55.06348623634249],
]

export const retails = [
	[25.044992369687293, 55.121435698775485],
	[25.075898471375968, 55.13856772114879],
	[25.09329964198724, 55.15229836321515],
	[25.113815477680095, 55.13651651443301],
	[25.119382990063837, 55.20104959527127],
	[25.084559394297365, 55.22024433323858],
	[25.063327640607476, 55.30396966344258],
	[25.063989538188025, 55.302896680241055],
	[25.07276901956505, 55.399947453216754],
	[25.216960809910518, 55.25277204065001],
	[25.19851060696854, 55.25568345552477],
	[25.199841503446518, 55.27466267228355],
	[25.19749016676529, 55.279859177566685],
	[25.25066622631595, 55.3322706000487],
	[25.221697183755595, 55.35281742999902],
	[25.173799194849867, 55.422122152688196],
	[25.229790106695624, 55.31986009109691],
	[25.027612715661895, 55.15307810941047],
	[25.024968455176758, 55.13007548533282],
	[25.028610803423224, 55.147332098351576],
	[25.022332504813672, 55.1401868010836],
	[25.03325392181623, 55.135784143170994],
	[24.90977328043599, 55.117243557004734],
	[24.909231815983585, 55.117117872591884],
	[24.909222316586007, 55.116478976826585],
	[24.91054272583322, 55.119191665403825],
	[24.86668073809571, 55.14387986641911],
	[24.89076371825692, 55.15104043174654],
	[24.989072537067514, 55.02986491467617],
	[24.967560444514874, 55.04840536920418],
	[24.96167757673806, 55.062681519190754],
	[24.972266535999875, 55.096981360382465],
	[24.999659138363516, 55.08975058279468],
	[25.00873264189898, 55.095498124100736],
]

export const dubai_islands = [
    { lat: 25.298743, lng: 55.290571 },
    { lat: 25.303903, lng: 55.293486 },
    { lat: 25.304679, lng: 55.296576 },
    { lat: 25.306075, lng: 55.296061 },
    { lat: 25.309489, lng: 55.290568 },
    { lat: 25.311662, lng: 55.288851 },
    { lat: 25.313834, lng: 55.285418 },
    { lat: 25.312748, lng: 55.283701 },
    { lat: 25.310110, lng: 55.282671 },
    { lat: 25.305455, lng: 55.281298 },
    { lat: 25.301885, lng: 55.283358 },
    { lat: 25.299402, lng: 55.285589 },
    { lat: 25.295367, lng: 55.287134 },
    { lat: 25.296919, lng: 55.288336 },
    { lat: 25.295522, lng: 55.289538 },
    { lat: 25.294280, lng: 55.290911 },
    { lat: 25.291642, lng: 55.290396 },
    { lat: 25.289935, lng: 55.290224 },
    { lat: 25.290090, lng: 55.292799 },
    { lat: 25.285278, lng: 55.294516 },
    { lat: 25.284968, lng: 55.292628 },
    { lat: 25.279225, lng: 55.294688 },
    { lat: 25.282484, lng: 55.300696 },
    { lat: 25.283260, lng: 55.300524 },
    { lat: 25.283726, lng: 55.301726 },
    { lat: 25.283105, lng: 55.302069 },
    { lat: 25.290555, lng: 55.313742 },
    { lat: 25.294591, lng: 55.317862 },
    { lat: 25.294746, lng: 55.319922 },
    { lat: 25.298471, lng: 55.324213 },
    { lat: 25.300178, lng: 55.320780 },
    { lat: 25.301885, lng: 55.316660 },
    { lat: 25.301730, lng: 55.315115 },
    { lat: 25.303437, lng: 55.315974 },
    { lat: 25.304523, lng: 55.317347 },
    { lat: 25.304213, lng: 55.319063 },
    { lat: 25.303282, lng: 55.320780 },
    { lat: 25.302661, lng: 55.320265 },
    { lat: 25.300799, lng: 55.325072 },
    { lat: 25.301264, lng: 55.325758 },
    { lat: 25.302195, lng: 55.325758 },
    { lat: 25.303127, lng: 55.325243 },
    { lat: 25.303747, lng: 55.325415 },
    { lat: 25.303592, lng: 55.326617 },
    { lat: 25.304213, lng: 55.327647 },
    { lat: 25.305144, lng: 55.327818 },
    { lat: 25.306386, lng: 55.327303 },
    { lat: 25.307162, lng: 55.326445 },
    { lat: 25.307782, lng: 55.326960 },
    { lat: 25.307162, lng: 55.327990 },
    { lat: 25.307162, lng: 55.329020 },
    { lat: 25.307938, lng: 55.329535 },
    { lat: 25.308558, lng: 55.329706 },
    { lat: 25.311507, lng: 55.329535 },
    { lat: 25.322369, lng: 55.331423 },
    { lat: 25.322369, lng: 55.330050 },
    { lat: 25.323300, lng: 55.329706 },
    { lat: 25.324541, lng: 55.328848 },
    { lat: 25.325627, lng: 55.328505 },
    { lat: 25.326714, lng: 55.328848 },
    { lat: 25.327489, lng: 55.330050 },
    { lat: 25.331679, lng: 55.324728 },
    { lat: 25.331368, lng: 55.323355 },
    { lat: 25.330903, lng: 55.321810 },
    { lat: 25.330903, lng: 55.320608 },
    { lat: 25.331834, lng: 55.319922 },
    { lat: 25.333075, lng: 55.320093 },
    { lat: 25.333696, lng: 55.319235 },
    { lat: 25.333851, lng: 55.317175 },
    { lat: 25.333851, lng: 55.315630 },
    { lat: 25.334471, lng: 55.314257 },
    { lat: 25.336954, lng: 55.310652 },
    { lat: 25.338660, lng: 55.311339 },
    { lat: 25.338971, lng: 55.312025 },
    { lat: 25.338350, lng: 55.312369 },
    { lat: 25.339591, lng: 55.313055 },
    { lat: 25.340367, lng: 55.311682 },
    { lat: 25.340988, lng: 55.311682 },
    { lat: 25.342074, lng: 55.310480 },
    { lat: 25.343866, lng: 55.308688 },
    { lat: 25.345262, lng: 55.309031 },
    { lat: 25.347900, lng: 55.305426 },
    { lat: 25.348986, lng: 55.301306 },
    { lat: 25.350072, lng: 55.298559 },
    { lat: 25.351002, lng: 55.298216 },
    { lat: 25.351468, lng: 55.297701 },
    { lat: 25.352709, lng: 55.298559 },
    { lat: 25.353019, lng: 55.297358 },
    { lat: 25.353484, lng: 55.296500 },
    { lat: 25.354570, lng: 55.296328 },
    { lat: 25.354881, lng: 55.296843 },
    { lat: 25.356432, lng: 55.294611 },
    { lat: 25.356432, lng: 55.293753 },
    { lat: 25.356277, lng: 55.292723 },
    { lat: 25.354881, lng: 55.292551 },
    { lat: 25.352554, lng: 55.293581 },
    { lat: 25.346969, lng: 55.294268 },
    { lat: 25.348210, lng: 55.294955 },
    { lat: 25.348210, lng: 55.296156 },
    { lat: 25.347279, lng: 55.297186 },
    { lat: 25.345728, lng: 55.297701 },
    { lat: 25.343866, lng: 55.297873 },
    { lat: 25.342625, lng: 55.297358 },
    { lat: 25.341694, lng: 55.297186 },
    { lat: 25.340608, lng: 55.295813 },
    { lat: 25.339677, lng: 55.294611 },
    { lat: 25.339988, lng: 55.293238 },
    { lat: 25.334868, lng: 55.292551 },
    { lat: 25.331610, lng: 55.292895 },
    { lat: 25.330989, lng: 55.293410 },
    { lat: 25.329282, lng: 55.295985 },
    { lat: 25.327265, lng: 55.297701 },
    { lat: 25.325248, lng: 55.299246 },
    { lat: 25.324317, lng: 55.300963 },
    { lat: 25.322455, lng: 55.302851 },
    { lat: 25.320593, lng: 55.305083 },
    { lat: 25.320283, lng: 55.307314 },
    { lat: 25.317955, lng: 55.310232 },
    { lat: 25.316403, lng: 55.312636 },
    { lat: 25.314852, lng: 55.312636 },
    { lat: 25.315627, lng: 55.313666 },
    { lat: 25.315162, lng: 55.314009 },
    { lat: 25.315317, lng: 55.315211 },
    { lat: 25.315162, lng: 55.316412 },
    { lat: 25.317024, lng: 55.318129 },
    { lat: 25.322610, lng: 55.320532 },
    { lat: 25.326800, lng: 55.320360 },
    { lat: 25.326955, lng: 55.321390 },
    { lat: 25.325713, lng: 55.322592 },
    { lat: 25.321369, lng: 55.322420 },
    { lat: 25.315783, lng: 55.320532 },
    { lat: 25.313455, lng: 55.318301 },
    { lat: 25.312524, lng: 55.317786 },
    { lat: 25.311748, lng: 55.316069 },
    { lat: 25.310817, lng: 55.314352 },
    { lat: 25.308334, lng: 55.311262 },
    { lat: 25.306937, lng: 55.311262 },
    { lat: 25.305696, lng: 55.310576 },
    { lat: 25.304765, lng: 55.312121 },
    { lat: 25.303523, lng: 55.312636 },
    { lat: 25.302282, lng: 55.311434 },
    { lat: 25.302126, lng: 55.310061 },
    { lat: 25.302126, lng: 55.308516 },
    { lat: 25.301971, lng: 55.306284 },
    { lat: 25.302902, lng: 55.305426 },
    { lat: 25.303989, lng: 55.304396 },
    { lat: 25.303523, lng: 55.301478 },
    { lat: 25.303058, lng: 55.298731 },
    { lat: 25.301971, lng: 55.296156 },
    { lat: 25.300419, lng: 55.294611 },
    { lat: 25.299643, lng: 55.292895 },
    { lat: 25.298091, lng: 55.293238 },
    { lat: 25.297160, lng: 55.292208 },
    { lat: 25.298712, lng: 55.290835 },
]

export const palm_jumeirah = [
	{ lat: 25.130750, lng: 55.119406 },
	{ lat: 25.125891, lng: 55.115245 },
	{ lat: 25.122783, lng: 55.113357 },
	{ lat: 25.118586, lng: 55.111812 },
	{ lat: 25.115322, lng: 55.111469 },
	{ lat: 25.110659, lng: 55.111469 },
	{ lat: 25.106151, lng: 55.113014 },
	{ lat: 25.104752, lng: 55.113529 },
	{ lat: 25.102887, lng: 55.113014 },
	{ lat: 25.101799, lng: 55.114559 },
	{ lat: 25.103198, lng: 55.114902 },
	{ lat: 25.101022, lng: 55.119194 },
	{ lat: 25.100244, lng: 55.123485 },
	{ lat: 25.099622, lng: 55.128463 },
	{ lat: 25.101022, lng: 55.133785 },
	{ lat: 25.099156, lng: 55.135330 },
	{ lat: 25.098379, lng: 55.132926 },
	{ lat: 25.097291, lng: 55.126747 },
	{ lat: 25.098223, lng: 55.120395 },
	{ lat: 25.099467, lng: 55.116447 },
	{ lat: 25.102265, lng: 55.112842 },
	{ lat: 25.107084, lng: 55.109752 },
	{ lat: 25.112524, lng: 55.108551 },
	{ lat: 25.117343, lng: 55.108207 },
	{ lat: 25.121850, lng: 55.109409 },
	{ lat: 25.126979, lng: 55.111469 },
	{ lat: 25.132108, lng: 55.116104 },
	{ lat: 25.137081, lng: 55.121769 },
	{ lat: 25.140189, lng: 55.128463 },
	{ lat: 25.141432, lng: 55.132583 },
	{ lat: 25.141743, lng: 55.139793 },
	{ lat: 25.140655, lng: 55.143741 },
	{ lat: 25.139101, lng: 55.147518 },
	{ lat: 25.135527, lng: 55.151123 },
	{ lat: 25.130243, lng: 55.154041 },
	{ lat: 25.126513, lng: 55.154899 },
	{ lat: 25.123093, lng: 55.154899 },
	{ lat: 25.119830, lng: 55.154213 },
	{ lat: 25.120451, lng: 55.151638 },
	{ lat: 25.124803, lng: 55.151981 },
	{ lat: 25.128378, lng: 55.151981 },
	{ lat: 25.131331, lng: 55.150779 },
	{ lat: 25.133972, lng: 55.149234 },
	{ lat: 25.135682, lng: 55.148033 },
	{ lat: 25.136770, lng: 55.149234 },
	{ lat: 25.137858, lng: 55.148204 },
	{ lat: 25.136770, lng: 55.146488 },
	{ lat: 25.138168, lng: 55.143398 },
	{ lat: 25.138635, lng: 55.140651 },
	{ lat: 25.139101, lng: 55.136875 },
	{ lat: 25.138790, lng: 55.133441 },
	{ lat: 25.138013, lng: 55.130352 },
	{ lat: 25.136925, lng: 55.128978 },
	{ lat: 25.136304, lng: 55.127605 },
	{ lat: 25.135837, lng: 55.126060 },
	{ lat: 25.134439, lng: 55.124858 },
	{ lat: 25.133506, lng: 55.122627 },
	{ lat: 25.133506, lng: 55.121597 },
	{ lat: 25.131797, lng: 55.120224 },
	{ lat: 25.128533, lng: 55.120567 },
	{ lat: 25.127756, lng: 55.122283 },
	{ lat: 25.127911, lng: 55.123485 },
	{ lat: 25.129466, lng: 55.122970 },
	{ lat: 25.130243, lng: 55.123313 },
	{ lat: 25.130243, lng: 55.124515 },
	{ lat: 25.129155, lng: 55.125202 },
	{ lat: 25.125736, lng: 55.125888 },
	{ lat: 25.130087, lng: 55.126060 },
	{ lat: 25.132263, lng: 55.126575 },
	{ lat: 25.132574, lng: 55.127090 },
	{ lat: 25.132574, lng: 55.128120 },
	{ lat: 25.131641, lng: 55.128807 },
	{ lat: 25.130709, lng: 55.127777 },
	{ lat: 25.127134, lng: 55.127777 },
	{ lat: 25.123871, lng: 55.128463 },
	{ lat: 25.131797, lng: 55.130523 },
	{ lat: 25.135060, lng: 55.133270 },
	{ lat: 25.134905, lng: 55.134128 },
	{ lat: 25.134128, lng: 55.134986 },
	{ lat: 25.132108, lng: 55.133270 },
	{ lat: 25.129932, lng: 55.131725 },
	{ lat: 25.126202, lng: 55.130523 },
	{ lat: 25.122627, lng: 55.130180 },
	{ lat: 25.127445, lng: 55.132068 },
	{ lat: 25.130864, lng: 55.134471 },
	{ lat: 25.132885, lng: 55.136703 },
	{ lat: 25.135371, lng: 55.139793 },
	{ lat: 25.135371, lng: 55.140651 },
	{ lat: 25.134594, lng: 55.140823 },
	{ lat: 25.133817, lng: 55.140823 },
	{ lat: 25.133040, lng: 55.139278 },
	{ lat: 25.132263, lng: 55.137390 },
	{ lat: 25.130087, lng: 55.135673 },
	{ lat: 25.126046, lng: 55.132926 },
	{ lat: 25.122005, lng: 55.131382 },
	{ lat: 25.126513, lng: 55.135330 },
	{ lat: 25.130087, lng: 55.138935 },
	{ lat: 25.131175, lng: 55.142025 },
	{ lat: 25.132108, lng: 55.144599 },
	{ lat: 25.132108, lng: 55.145801 },
	{ lat: 25.131020, lng: 55.146144 },
	{ lat: 25.130398, lng: 55.145286 },
	{ lat: 25.129776, lng: 55.141853 },
	{ lat: 25.128067, lng: 55.139106 },
	{ lat: 25.126046, lng: 55.136531 },
	{ lat: 25.121073, lng: 55.132926 },
	{ lat: 25.119985, lng: 55.132240 },
	{ lat: 25.124026, lng: 55.137218 },
	{ lat: 25.126202, lng: 55.140308 },
	{ lat: 25.127911, lng: 55.143741 },
	{ lat: 25.128533, lng: 55.145629 },
	{ lat: 25.128689, lng: 55.146831 },
	{ lat: 25.127911, lng: 55.147518 },
	{ lat: 25.126979, lng: 55.147689 },
	{ lat: 25.126046, lng: 55.144428 },
	{ lat: 25.125736, lng: 55.142883 },
	{ lat: 25.125269, lng: 55.140995 },
	{ lat: 25.124337, lng: 55.139106 },
	{ lat: 25.122316, lng: 55.137218 },
	{ lat: 25.119830, lng: 55.134128 },
	{ lat: 25.121850, lng: 55.138420 },
	{ lat: 25.123560, lng: 55.142711 },
	{ lat: 25.124648, lng: 55.147003 },
	{ lat: 25.124181, lng: 55.147861 },
	{ lat: 25.123093, lng: 55.147689 },
	{ lat: 25.122627, lng: 55.145629 },
	{ lat: 25.122161, lng: 55.141853 },
	{ lat: 25.119674, lng: 55.136360 },
	{ lat: 25.118742, lng: 55.135501 },
	{ lat: 25.120296, lng: 55.139106 },
	{ lat: 25.120762, lng: 55.143570 },
	{ lat: 25.121073, lng: 55.146316 },
	{ lat: 25.120762, lng: 55.147346 },
	{ lat: 25.120296, lng: 55.147861 },
	{ lat: 25.119363, lng: 55.147518 },
	{ lat: 25.119052, lng: 55.146831 },
	{ lat: 25.119052, lng: 55.145458 },
	{ lat: 25.119830, lng: 55.142883 },
	{ lat: 25.119052, lng: 55.140480 },
	{ lat: 25.117809, lng: 55.136016 },
	{ lat: 25.116566, lng: 55.137046 },
	{ lat: 25.117032, lng: 55.138076 },
	{ lat: 25.117032, lng: 55.139106 },
	{ lat: 25.116410, lng: 55.139793 },
	{ lat: 25.118431, lng: 55.142025 },
	{ lat: 25.117809, lng: 55.142540 },
	{ lat: 25.116255, lng: 55.142883 },
	{ lat: 25.114856, lng: 55.143055 },
	{ lat: 25.112680, lng: 55.145629 },
	{ lat: 25.114079, lng: 55.146488 },
	{ lat: 25.115167, lng: 55.146659 },
	{ lat: 25.115478, lng: 55.147689 },
	{ lat: 25.114856, lng: 55.148719 },
	{ lat: 25.114079, lng: 55.149234 },
	{ lat: 25.113301, lng: 55.149234 },
	{ lat: 25.112835, lng: 55.148204 },
	{ lat: 25.111903, lng: 55.147689 },
	{ lat: 25.110815, lng: 55.147861 },
	{ lat: 25.109416, lng: 55.150436 },
	{ lat: 25.107861, lng: 55.152153 },
	{ lat: 25.107550, lng: 55.152839 },
	{ lat: 25.105530, lng: 55.153011 },
	{ lat: 25.103509, lng: 55.151294 },
	{ lat: 25.101799, lng: 55.148033 },
	{ lat: 25.108483, lng: 55.140480 },
	{ lat: 25.109105, lng: 55.137390 },
	{ lat: 25.110037, lng: 55.137218 },
	{ lat: 25.109882, lng: 55.134986 },
	{ lat: 25.111747, lng: 55.136875 },
	{ lat: 25.112835, lng: 55.135673 },
	{ lat: 25.113768, lng: 55.135501 },
	{ lat: 25.114700, lng: 55.135501 },
	{ lat: 25.115788, lng: 55.134643 },
	{ lat: 25.111747, lng: 55.133613 },
	{ lat: 25.108483, lng: 55.134128 },
	{ lat: 25.105840, lng: 55.134815 },
	{ lat: 25.104752, lng: 55.133956 },
	{ lat: 25.104752, lng: 55.133098 },
	{ lat: 25.109726, lng: 55.132068 },
	{ lat: 25.112680, lng: 55.132068 },
	{ lat: 25.115944, lng: 55.132755 },
	{ lat: 25.110970, lng: 55.131382 },
	{ lat: 25.107550, lng: 55.130695 },
	{ lat: 25.105063, lng: 55.131382 },
	{ lat: 25.103975, lng: 55.130008 },
	{ lat: 25.104441, lng: 55.128463 },
	{ lat: 25.110348, lng: 55.128635 },
	{ lat: 25.113457, lng: 55.130180 },
	{ lat: 25.117187, lng: 55.131897 },
	{ lat: 25.113768, lng: 55.128978 },
	{ lat: 25.110348, lng: 55.127605 },
	{ lat: 25.107084, lng: 55.126575 },
	{ lat: 25.104131, lng: 55.126403 },
	{ lat: 25.103509, lng: 55.125030 },
	{ lat: 25.103820, lng: 55.124172 },
	{ lat: 25.106618, lng: 55.124172 },
	{ lat: 25.110348, lng: 55.126060 },
	{ lat: 25.113146, lng: 55.127090 },
	{ lat: 25.115633, lng: 55.128463 },
	{ lat: 25.117964, lng: 55.131210 },
	{ lat: 25.116255, lng: 55.127948 },
	{ lat: 25.113768, lng: 55.125717 },
	{ lat: 25.111592, lng: 55.124343 },
	{ lat: 25.109105, lng: 55.122970 },
	{ lat: 25.105996, lng: 55.122283 },
	{ lat: 25.104752, lng: 55.122112 },
	{ lat: 25.104441, lng: 55.121082 },
	{ lat: 25.104441, lng: 55.120224 },
	{ lat: 25.105530, lng: 55.119709 },
	{ lat: 25.110193, lng: 55.121597 },
	{ lat: 25.114234, lng: 55.124000 },
	{ lat: 25.116410, lng: 55.126232 },
	{ lat: 25.119052, lng: 55.130008 },
	{ lat: 25.116410, lng: 55.124858 },
	{ lat: 25.114700, lng: 55.122455 },
	{ lat: 25.111592, lng: 55.120052 },
	{ lat: 25.109105, lng: 55.118164 },
	{ lat: 25.108017, lng: 55.116104 },
	{ lat: 25.109260, lng: 55.115417 },
	{ lat: 25.111281, lng: 55.116962 },
	{ lat: 25.114700, lng: 55.119709 },
	{ lat: 25.116410, lng: 55.121940 },
	{ lat: 25.117498, lng: 55.124000 },
	{ lat: 25.120140, lng: 55.128463 },
	{ lat: 25.118742, lng: 55.122798 },
	{ lat: 25.116876, lng: 55.120052 },
	{ lat: 25.114234, lng: 55.117305 },
	{ lat: 25.113768, lng: 55.115932 },
	{ lat: 25.114390, lng: 55.114730 },
	{ lat: 25.115633, lng: 55.114902 },
	{ lat: 25.117809, lng: 55.118850 },
	{ lat: 25.120140, lng: 55.122970 },
	{ lat: 25.121539, lng: 55.126060 },
	{ lat: 25.121539, lng: 55.121940 },
	{ lat: 25.120296, lng: 55.118679 },
	{ lat: 25.119830, lng: 55.116619 },
	{ lat: 25.120607, lng: 55.116447 },
	{ lat: 25.121384, lng: 55.116447 },
	{ lat: 25.121539, lng: 55.117992 },
	{ lat: 25.121850, lng: 55.120052 },
	{ lat: 25.122472, lng: 55.121940 },
	{ lat: 25.122938, lng: 55.124000 },
	{ lat: 25.122627, lng: 55.125202 },
	{ lat: 25.123715, lng: 55.122283 },
	{ lat: 25.123404, lng: 55.120395 },
	{ lat: 25.123560, lng: 55.119022 },
	{ lat: 25.124337, lng: 55.118507 },
	{ lat: 25.124803, lng: 55.119194 },
	{ lat: 25.124648, lng: 55.120910 },
	{ lat: 25.125425, lng: 55.121769 },
	{ lat: 25.126357, lng: 55.121254 },
	{ lat: 25.126979, lng: 55.120739 },
	{ lat: 25.127756, lng: 55.120910 },
	{ lat: 25.130864, lng: 55.119880 },
] 

export const palm_jumeirah2 = [
	{ lat: 25.102887, lng: 55.143226 },
	{ lat: 25.096203, lng: 55.137218 },
	{ lat: 25.096513, lng: 55.135501 },
	{ lat: 25.093715, lng: 55.135501 },
	{ lat: 25.095736, lng: 55.134815 },
	{ lat: 25.092627, lng: 55.125545 },
	{ lat: 25.089051, lng: 55.126403 },
	{ lat: 25.090451, lng: 55.127433 },
	{ lat: 25.092161, lng: 55.131210 },
	{ lat: 25.092161, lng: 55.132240 },
	{ lat: 25.093404, lng: 55.132583 },
	{ lat: 25.093560, lng: 55.134471 },
	{ lat: 25.093249, lng: 55.137218 },
	{ lat: 25.095270, lng: 55.138935 },
	{ lat: 25.100711, lng: 55.144084 },
	{ lat: 25.102576, lng: 55.144084 },
	{ lat: 25.102887, lng: 55.143570 },
]

export const palm_jebel_ali = [
	{ lat: 24.985744, lng: 55.016328 },
	{ lat: 24.992279, lng: 55.015470 },
	{ lat: 24.994924, lng: 55.016843 },
	{ lat: 24.995857, lng: 55.018560 },
	{ lat: 24.996946, lng: 55.019761 },
	{ lat: 24.999280, lng: 55.021135 },
	{ lat: 25.004570, lng: 55.023366 },
	{ lat: 25.011881, lng: 55.023709 },
	{ lat: 25.019815, lng: 55.021993 },
	{ lat: 25.022304, lng: 55.020963 },
	{ lat: 25.021526, lng: 55.019418 },
	{ lat: 25.023548, lng: 55.018560 },
	{ lat: 25.024637, lng: 55.019590 },
	{ lat: 25.031325, lng: 55.013238 },
	{ lat: 25.033347, lng: 55.008260 },
	{ lat: 25.035058, lng: 55.002423 },
	{ lat: 25.035680, lng: 54.997960 },
	{ lat: 25.036458, lng: 54.994355 },
	{ lat: 25.036458, lng: 54.989549 },
	{ lat: 25.032881, lng: 54.988691 },
	{ lat: 25.032258, lng: 54.995557 },
	{ lat: 25.031792, lng: 55.000879 },
	{ lat: 25.030392, lng: 55.005513 },
	{ lat: 25.028681, lng: 55.009977 },
	{ lat: 25.024481, lng: 55.014096 },
	{ lat: 25.023237, lng: 55.015470 },
	{ lat: 25.022459, lng: 55.015126 },
	{ lat: 25.023081, lng: 55.017015 },
	{ lat: 25.021215, lng: 55.018216 },
	{ lat: 25.020593, lng: 55.016671 },
	{ lat: 25.019815, lng: 55.017701 },
	{ lat: 25.014681, lng: 55.019418 },
	{ lat: 25.008148, lng: 55.019246 },
	{ lat: 25.002858, lng: 55.018045 },
	{ lat: 24.998035, lng: 55.015470 },
	{ lat: 24.997569, lng: 55.014440 },
	{ lat: 24.996635, lng: 55.015641 },
	{ lat: 24.993368, lng: 55.014440 },
	{ lat: 24.993212, lng: 55.012895 },
	{ lat: 24.991345, lng: 55.012380 },
	{ lat: 24.987767, lng: 55.012036 },
	{ lat: 24.984810, lng: 55.012723 },
	{ lat: 24.985122, lng: 55.014096 },
	{ lat: 24.984344, lng: 55.014268 },
	{ lat: 24.985122, lng: 55.016328 },
]

export const palm_jebel_ali2 = [
	{ lat: 24.979676, lng: 55.007058 },
	{ lat: 24.980142, lng: 55.004655 },
	{ lat: 24.981387, lng: 55.003968 },
	{ lat: 24.982645, lng: 55.005565 },
	{ lat: 24.982645, lng: 55.005823 },
	{ lat: 24.983773, lng: 55.005909 },
	{ lat: 24.984026, lng: 55.004604 },
	{ lat: 24.999896, lng: 54.995248 },
	{ lat: 25.000129, lng: 54.994648 },
	{ lat: 25.000907, lng: 54.994219 },
	{ lat: 25.001218, lng: 54.993875 },
	{ lat: 25.000674, lng: 54.992502 },
	{ lat: 25.001763, lng: 54.991640 },
	{ lat: 25.002541, lng: 54.993185 },
	{ lat: 25.002541, lng: 54.996447 },
	{ lat: 25.001918, lng: 54.998764 },
	{ lat: 25.000985, lng: 55.001854 },
	{ lat: 24.999274, lng: 55.004257 },
	{ lat: 24.997329, lng: 55.006317 },
	{ lat: 24.997873, lng: 55.008377 },
	{ lat: 24.999507, lng: 55.007605 },
	{ lat: 25.001996, lng: 55.003056 },
	{ lat: 25.003241, lng: 54.999966 },
	{ lat: 25.003863, lng: 54.996704 },
	{ lat: 25.003863, lng: 54.992584 },
	{ lat: 25.004485, lng: 54.992413 },
	{ lat: 25.004797, lng: 54.994215 },
	{ lat: 25.005263, lng: 54.997563 },
	{ lat: 25.005030, lng: 55.001339 },
	{ lat: 25.004019, lng: 55.004772 },
	{ lat: 25.003163, lng: 55.007433 },
	{ lat: 25.002152, lng: 55.009236 },
	{ lat: 25.001841, lng: 55.010266 },
	{ lat: 25.002852, lng: 55.011038 },
	{ lat: 25.003396, lng: 55.010952 },
	{ lat: 25.004096, lng: 55.009493 },
	{ lat: 25.004797, lng: 55.007691 },
	{ lat: 25.005419, lng: 55.005717 },
	{ lat: 25.005808, lng: 55.004429 },
	{ lat: 25.005963, lng: 55.002712 },
	{ lat: 25.006663, lng: 54.998507 },
	{ lat: 25.005808, lng: 54.992155 },
	{ lat: 25.006663, lng: 54.991469 },
	{ lat: 25.007519, lng: 54.994644 },
	{ lat: 25.008297, lng: 54.997648 },
	{ lat: 25.009075, lng: 55.000567 },
	{ lat: 25.009075, lng: 55.003056 },
	{ lat: 25.008375, lng: 55.006146 },
	{ lat: 25.007752, lng: 55.008892 },
	{ lat: 25.006974, lng: 55.010866 },
	{ lat: 25.007675, lng: 55.012154 },
	{ lat: 25.008452, lng: 55.011811 },
	{ lat: 25.009386, lng: 55.009579 },
	{ lat: 25.009930, lng: 55.006317 },
	{ lat: 25.010241, lng: 55.004515 },
	{ lat: 25.010241, lng: 55.000653 },
	{ lat: 25.009697, lng: 54.996447 },
	{ lat: 25.008686, lng: 54.993099 },
	{ lat: 25.007752, lng: 54.990610 },
	{ lat: 25.008608, lng: 54.990095 },
	{ lat: 25.009775, lng: 54.991898 },
	{ lat: 25.010630, lng: 54.994129 },
	{ lat: 25.011330, lng: 54.996447 },
	{ lat: 25.012575, lng: 54.999365 },
	{ lat: 25.013119, lng: 55.002455 },
	{ lat: 25.012653, lng: 55.005802 },
	{ lat: 25.012264, lng: 55.008806 },
	{ lat: 25.011564, lng: 55.010437 },
	{ lat: 25.012186, lng: 55.011811 },
	{ lat: 25.013353, lng: 55.011725 },
	{ lat: 25.013975, lng: 55.009236 },
	{ lat: 25.014208, lng: 55.006575 },
	{ lat: 25.014519, lng: 55.003571 },
	{ lat: 25.014286, lng: 55.000910 },
	{ lat: 25.013508, lng: 54.997906 },
	{ lat: 25.012419, lng: 54.995074 },
	{ lat: 25.011719, lng: 54.992756 },
	{ lat: 25.010475, lng: 54.990439 },
	{ lat: 25.009775, lng: 54.989065 },
	{ lat: 25.010241, lng: 54.988894 },
	{ lat: 25.013119, lng: 54.991726 },
	{ lat: 25.016464, lng: 54.996876 },
	{ lat: 25.017242, lng: 54.999794 },
	{ lat: 25.017942, lng: 55.002970 },
	{ lat: 25.018097, lng: 55.006060 },
	{ lat: 25.018019, lng: 55.007948 },
	{ lat: 25.018797, lng: 55.008806 },
	{ lat: 25.019575, lng: 55.008721 },
	{ lat: 25.019808, lng: 55.008120 },
	{ lat: 25.019808, lng: 55.005974 },
	{ lat: 25.019808, lng: 55.003227 },
	{ lat: 25.019108, lng: 55.001082 },
	{ lat: 25.018331, lng: 54.997734 },
	{ lat: 25.016075, lng: 54.993357 },
	{ lat: 25.011797, lng: 54.988293 },
	{ lat: 25.012030, lng: 54.987435 },
	{ lat: 25.017319, lng: 54.991211 },
	{ lat: 25.018797, lng: 54.993958 },
	{ lat: 25.020742, lng: 54.997305 },
	{ lat: 25.022219, lng: 55.001940 },
	{ lat: 25.022219, lng: 55.003485 },
	{ lat: 25.023619, lng: 55.003914 },
	{ lat: 25.024008, lng: 55.003142 },
	{ lat: 25.023775, lng: 55.001168 },
	{ lat: 25.022375, lng: 54.996361 },
	{ lat: 25.019808, lng: 54.992499 },
	{ lat: 25.014519, lng: 54.987263 },
	{ lat: 25.014908, lng: 54.986061 },
	{ lat: 25.020430, lng: 54.989065 },
	{ lat: 25.023153, lng: 54.992155 },
	{ lat: 25.025564, lng: 54.995674 },
	{ lat: 25.026886, lng: 54.995417 },
	{ lat: 25.027352, lng: 54.994902 },
	{ lat: 25.026575, lng: 54.993529 },
	{ lat: 25.024008, lng: 54.989752 },
	{ lat: 25.021830, lng: 54.988121 },
	{ lat: 25.016853, lng: 54.985031 },
	{ lat: 25.016464, lng: 54.984860 },
	{ lat: 25.016464, lng: 54.984259 },
	{ lat: 25.019808, lng: 54.984946 },
	{ lat: 25.020664, lng: 54.985546 },
	{ lat: 25.021908, lng: 54.985117 },
	{ lat: 25.020975, lng: 54.983572 },
	{ lat: 25.019186, lng: 54.982714 },
	{ lat: 25.018953, lng: 54.982027 },
	{ lat: 25.018953, lng: 54.981341 },
	{ lat: 25.019419, lng: 54.980654 },
	{ lat: 25.019497, lng: 54.980053 },
	{ lat: 25.018486, lng: 54.979967 },
	{ lat: 25.018019, lng: 54.980311 },
	{ lat: 25.017942, lng: 54.979710 },
	{ lat: 25.017864, lng: 54.978766 },
	{ lat: 25.018097, lng: 54.976792 },
	{ lat: 25.018642, lng: 54.974045 },
	{ lat: 25.018486, lng: 54.972414 },
	{ lat: 25.018408, lng: 54.969925 },
	{ lat: 25.017864, lng: 54.969324 },
	{ lat: 25.017242, lng: 54.969324 },
	{ lat: 25.016853, lng: 54.969839 },
	{ lat: 25.016775, lng: 54.970526 },
	{ lat: 25.016775, lng: 54.971470 },
	{ lat: 25.017008, lng: 54.973530 },
	{ lat: 25.016697, lng: 54.975418 },
	{ lat: 25.016153, lng: 54.977822 },
	{ lat: 25.015142, lng: 54.980482 },
	{ lat: 25.014830, lng: 54.980911 },
	{ lat: 25.014208, lng: 54.980826 },
	{ lat: 25.014519, lng: 54.979452 },
	{ lat: 25.014830, lng: 54.977307 },
	{ lat: 25.014830, lng: 54.975590 },
	{ lat: 25.014830, lng: 54.973702 },
	{ lat: 25.014519, lng: 54.971642 },
	{ lat: 25.013664, lng: 54.967951 },
	{ lat: 25.012730, lng: 54.965805 },
	{ lat: 25.011719, lng: 54.965719 },
	{ lat: 25.011408, lng: 54.966578 },
	{ lat: 25.011797, lng: 54.968380 },
	{ lat: 25.012886, lng: 54.971556 },
	{ lat: 25.013353, lng: 54.975075 },
	{ lat: 25.013275, lng: 54.977221 },
	{ lat: 25.013119, lng: 54.979195 },
	{ lat: 25.012575, lng: 54.980826 },
	{ lat: 25.012419, lng: 54.981598 },
	{ lat: 25.011486, lng: 54.981856 },
	{ lat: 25.011175, lng: 54.980911 },
	{ lat: 25.011019, lng: 54.977564 },
	{ lat: 25.010008, lng: 54.973358 },
	{ lat: 25.008997, lng: 54.969324 },
	{ lat: 25.006041, lng: 54.964432 },
	{ lat: 25.004485, lng: 54.963316 },
	{ lat: 25.003552, lng: 54.963917 },
	{ lat: 25.003319, lng: 54.964861 },
	{ lat: 25.004019, lng: 54.965977 },
	{ lat: 25.005263, lng: 54.967007 },
	{ lat: 25.007052, lng: 54.969410 },
	{ lat: 25.008375, lng: 54.973015 },
	{ lat: 25.009619, lng: 54.976963 },
	{ lat: 25.010086, lng: 54.980482 },
	{ lat: 25.010008, lng: 54.983315 },
	{ lat: 25.009308, lng: 54.983486 },
	{ lat: 25.008608, lng: 54.980997 },
	{ lat: 25.007752, lng: 54.976706 },
	{ lat: 25.005652, lng: 54.972758 },
	{ lat: 25.003007, lng: 54.968552 },
	{ lat: 24.999740, lng: 54.965891 },
	{ lat: 24.998496, lng: 54.964861 },
	{ lat: 24.997562, lng: 54.965119 },
	{ lat: 24.997562, lng: 54.966063 },
	{ lat: 24.997873, lng: 54.966835 },
	{ lat: 24.999429, lng: 54.967522 },
	{ lat: 25.001996, lng: 54.970183 },
	{ lat: 25.004485, lng: 54.973015 },
	{ lat: 25.005730, lng: 54.976448 },
	{ lat: 25.007363, lng: 54.979882 },
	{ lat: 25.007830, lng: 54.984602 },
	{ lat: 25.007130, lng: 54.984688 },
	{ lat: 25.004719, lng: 54.978508 },
	{ lat: 25.000596, lng: 54.973530 },
	{ lat: 24.996940, lng: 54.970869 },
	{ lat: 24.992506, lng: 54.968981 },
	{ lat: 24.991650, lng: 54.969410 },
	{ lat: 24.991417, lng: 54.970183 },
	{ lat: 24.991806, lng: 54.970955 },
	{ lat: 24.992039, lng: 54.971213 },
	{ lat: 24.993206, lng: 54.971470 },
	{ lat: 24.997562, lng: 54.972929 },
	{ lat: 24.999040, lng: 54.974131 },
	{ lat: 25.001918, lng: 54.977307 },
	{ lat: 25.004252, lng: 54.980826 },
	{ lat: 25.005963, lng: 54.985546 },
	{ lat: 25.005419, lng: 54.985976 },
	{ lat: 25.003552, lng: 54.983057 },
	{ lat: 25.001452, lng: 54.979882 },
	{ lat: 24.997329, lng: 54.976534 },
	{ lat: 24.993906, lng: 54.974646 },
	{ lat: 24.990094, lng: 54.973358 },
	{ lat: 24.988849, lng: 54.973873 },
	{ lat: 24.988538, lng: 54.974474 },
	{ lat: 24.989161, lng: 54.975332 },
	{ lat: 24.990094, lng: 54.975418 },
	{ lat: 24.993984, lng: 54.976620 },
	{ lat: 24.997095, lng: 54.978337 },
	{ lat: 25.000363, lng: 54.981255 },
	{ lat: 25.003707, lng: 54.986147 },
	{ lat: 25.004096, lng: 54.986490 },
	{ lat: 25.003241, lng: 54.987091 },
	{ lat: 25.000985, lng: 54.984602 },
	{ lat: 24.998418, lng: 54.982886 },
	{ lat: 24.994451, lng: 54.980311 },
	{ lat: 24.989161, lng: 54.978165 },
	{ lat: 24.987138, lng: 54.978766 },
	{ lat: 24.986982, lng: 54.979538 },
	{ lat: 24.987527, lng: 54.980397 },
	{ lat: 24.988149, lng: 54.980482 },
	{ lat: 24.990094, lng: 54.980654 },
	{ lat: 24.994373, lng: 54.981598 },
	{ lat: 24.997796, lng: 54.984173 },
	{ lat: 25.001374, lng: 54.987005 },
	{ lat: 25.002230, lng: 54.987950 },
	{ lat: 25.001763, lng: 54.988293 },
	{ lat: 25.000285, lng: 54.987263 },
	{ lat: 24.995540, lng: 54.984774 },
	{ lat: 24.992350, lng: 54.983830 },
	{ lat: 24.989627, lng: 54.983744 },
	{ lat: 24.987060, lng: 54.984345 },
	{ lat: 24.986282, lng: 54.984774 },
	{ lat: 24.986204, lng: 54.985632 },
	{ lat: 24.986904, lng: 54.986319 },
	{ lat: 24.987760, lng: 54.986490 },
	{ lat: 24.989316, lng: 54.986147 },
	{ lat: 24.992350, lng: 54.986319 },
	{ lat: 24.994762, lng: 54.986576 },
	{ lat: 24.996784, lng: 54.986834 },
	{ lat: 24.999196, lng: 54.988207 },
	{ lat: 25.000518, lng: 54.988980 },
	{ lat: 25.000829, lng: 54.989580 },
	{ lat: 25.000985, lng: 54.990267 },
	{ lat: 25.000129, lng: 54.990782 },
	{ lat: 24.999507, lng: 54.989666 },
	{ lat: 24.996940, lng: 54.990782 },
	{ lat: 24.982236, lng: 54.999537 },
	{ lat: 24.980680, lng: 54.999623 },
	{ lat: 24.979902, lng: 55.000567 },
	{ lat: 24.980447, lng: 55.000996 },
	{ lat: 24.980447, lng: 55.001854 },
	{ lat: 24.979124, lng: 55.002283 },
	{ lat: 24.978346, lng: 55.001940 },
	{ lat: 24.979591, lng: 55.006661 },
]

export const palm_jebel_ali3 = [
	{ lat: 24.974183, lng: 54.991840 },
	{ lat: 24.976439, lng: 54.989952 },
	{ lat: 24.977917, lng: 54.988236 },
	{ lat: 24.978462, lng: 54.987377 },
	{ lat: 24.977762, lng: 54.986433 },
	{ lat: 24.977762, lng: 54.985403 },
	{ lat: 24.978306, lng: 54.983429 },
	{ lat: 24.978929, lng: 54.982399 },
	{ lat: 24.981807, lng: 54.982142 },
	{ lat: 24.980952, lng: 54.981112 },
	{ lat: 24.982041, lng: 54.974159 },
	{ lat: 24.983908, lng: 54.968838 },
	{ lat: 24.986242, lng: 54.965233 },
	{ lat: 24.989354, lng: 54.961800 },
	{ lat: 24.991454, lng: 54.959654 },
	{ lat: 24.991921, lng: 54.959740 },
	{ lat: 24.992077, lng: 54.960341 },
	{ lat: 24.991921, lng: 54.958452 },
	{ lat: 24.992388, lng: 54.957766 },
	{ lat: 24.993166, lng: 54.957422 },
	{ lat: 24.993710, lng: 54.957079 },
	{ lat: 24.994333, lng: 54.958796 },
	{ lat: 24.994722, lng: 54.958367 },
	{ lat: 24.995577, lng: 54.958023 },
	{ lat: 25.001645, lng: 54.956135 },
	{ lat: 25.005845, lng: 54.956564 },
	{ lat: 25.009501, lng: 54.957422 },
	{ lat: 25.013546, lng: 54.959482 },
	{ lat: 25.017668, lng: 54.961886 },
	{ lat: 25.018446, lng: 54.962486 },
	{ lat: 25.019146, lng: 54.962744 },
	{ lat: 25.020157, lng: 54.962315 },
	{ lat: 25.021013, lng: 54.962315 },
	{ lat: 25.022490, lng: 54.964203 },
	{ lat: 25.024046, lng: 54.966091 },
	{ lat: 25.024590, lng: 54.967550 },
	{ lat: 25.025057, lng: 54.969010 },
	{ lat: 25.024824, lng: 54.970297 },
	{ lat: 25.027312, lng: 54.972614 },
	{ lat: 25.028557, lng: 54.974846 },
	{ lat: 25.029490, lng: 54.977078 },
	{ lat: 25.029879, lng: 54.980597 },
	{ lat: 25.031201, lng: 54.981283 },
	{ lat: 25.031978, lng: 54.982485 },
	{ lat: 25.032445, lng: 54.983343 },
	{ lat: 25.033223, lng: 54.984459 },
	{ lat: 25.032989, lng: 54.985832 },
	{ lat: 25.035556, lng: 54.986691 },
	{ lat: 25.036100, lng: 54.986176 },
	{ lat: 25.035789, lng: 54.985232 },
	{ lat: 25.035867, lng: 54.984459 },
	{ lat: 25.036489, lng: 54.982056 },
	{ lat: 25.036489, lng: 54.978966 },
	{ lat: 25.036022, lng: 54.975361 },
	{ lat: 25.035400, lng: 54.972872 },
	{ lat: 25.034000, lng: 54.969782 },
	{ lat: 25.032212, lng: 54.967379 },
	{ lat: 25.029801, lng: 54.965576 },
	{ lat: 25.027701, lng: 54.964031 },
	{ lat: 25.025757, lng: 54.963173 },
	{ lat: 25.024901, lng: 54.963173 },
	{ lat: 25.021635, lng: 54.959740 },
	{ lat: 25.016502, lng: 54.956135 },
	{ lat: 25.010357, lng: 54.953646 },
	{ lat: 25.003901, lng: 54.952530 },
	{ lat: 24.998144, lng: 54.952959 },
	{ lat: 24.993710, lng: 54.954247 },
	{ lat: 24.992543, lng: 54.954762 },
	{ lat: 24.992855, lng: 54.955877 },
	{ lat: 24.991843, lng: 54.956478 },
	{ lat: 24.990754, lng: 54.956650 },
	{ lat: 24.990210, lng: 54.955792 },
	{ lat: 24.988187, lng: 54.957594 },
	{ lat: 24.987642, lng: 54.957251 },
	{ lat: 24.984842, lng: 54.960255 },
	{ lat: 24.980796, lng: 54.965834 },
	{ lat: 24.979862, lng: 54.968151 },
	{ lat: 24.978384, lng: 54.972357 },
	{ lat: 24.977762, lng: 54.972786 },
	{ lat: 24.976672, lng: 54.972357 },
	{ lat: 24.976050, lng: 54.973816 },
	{ lat: 24.977450, lng: 54.974074 },
	{ lat: 24.977917, lng: 54.974417 },
	{ lat: 24.977528, lng: 54.976563 },
	{ lat: 24.977139, lng: 54.979996 },
	{ lat: 24.976984, lng: 54.980511 },
	{ lat: 24.977450, lng: 54.980511 },
	{ lat: 24.977139, lng: 54.983515 },
	{ lat: 24.976283, lng: 54.985146 },
	{ lat: 24.975116, lng: 54.986691 },
	{ lat: 24.973716, lng: 54.987120 },
	{ lat: 24.972160, lng: 54.987120 },
	{ lat: 24.973794, lng: 54.991840 },
]

export const the_gardens = [
	{ lat: 25.028722, lng: 55.161634 },
	{ lat: 25.023278, lng: 55.155970 },
	{ lat: 25.020789, lng: 55.151163 },
	{ lat: 25.019234, lng: 55.141722 },
	{ lat: 25.018767, lng: 55.139318 },
	{ lat: 25.017678, lng: 55.127645 },
	{ lat: 25.018602, lng: 55.120903 },
	{ lat: 25.023502, lng: 55.121504 },
	{ lat: 25.031279, lng: 55.131117 },
	{ lat: 25.034206, lng: 55.128248 },
	{ lat: 25.034362, lng: 55.122412 },
	{ lat: 25.032962, lng: 55.120610 },
	{ lat: 25.030785, lng: 55.120781 },
	{ lat: 25.028685, lng: 55.119494 },
	{ lat: 25.026818, lng: 55.115545 },
	{ lat: 25.026118, lng: 55.106276 },
	{ lat: 25.028996, lng: 55.104473 },
	{ lat: 25.032340, lng: 55.104645 },
	{ lat: 25.049243, lng: 55.119672 },
	{ lat: 25.046910, lng: 55.124564 },
	{ lat: 25.046988, lng: 55.127826 },
	{ lat: 25.047687, lng: 55.129457 },
	{ lat: 25.051045, lng: 55.133936 },
	{ lat: 25.030704, lng: 55.159717 },
]

export const jumeirah_islands = [
	{ lat: 25.031120, lng: 55.169203 },
	{ lat: 25.055537, lng: 55.140364 },
	{ lat: 25.057869, lng: 55.139849 },
	{ lat: 25.059735, lng: 55.140707 },
	{ lat: 25.061601, lng: 55.141222 },
	{ lat: 25.063467, lng: 55.140707 },
	{ lat: 25.070465, lng: 55.149119 },
	{ lat: 25.071242, lng: 55.151694 },
	{ lat: 25.065178, lng: 55.155985 },
	{ lat: 25.061135, lng: 55.158560 },
	{ lat: 25.056314, lng: 55.163195 },
	{ lat: 25.053360, lng: 55.168688 },
	{ lat: 25.047294, lng: 55.167143 },
	{ lat: 25.042784, lng: 55.169375 },
	{ lat: 25.039363, lng: 55.172636 },
	{ lat: 25.036408, lng: 55.176069 },
	{ lat: 25.031275, lng: 55.170920 },
]

export const jumeirah_village_triangle = [
	{ lat: 25.035033, lng: 55.177405 },
	{ lat: 25.037211, lng: 55.173629 },
	{ lat: 25.061007, lng: 55.195666 },
	{ lat: 25.058986, lng: 55.195323 },
	{ lat: 25.056342, lng: 55.194808 },
	{ lat: 25.053154, lng: 55.194636 },
	{ lat: 25.050199, lng: 55.195065 },
	{ lat: 25.047245, lng: 55.196353 },
	{ lat: 25.045301, lng: 55.197211 },
	{ lat: 25.044367, lng: 55.196524 },
	{ lat: 25.043357, lng: 55.195752 },
	{ lat: 25.042812, lng: 55.193863 },
	{ lat: 25.041568, lng: 55.189143 },
	{ lat: 25.039546, lng: 55.184680 },
	{ lat: 25.036436, lng: 55.179616 },
	{ lat: 25.035736, lng: 55.178242 },
]

export const jumeirah_village_circle = [
	{ lat: 25.047977, lng: 55.197560 },
	{ lat: 25.052876, lng: 55.196015 },
	{ lat: 25.058085, lng: 55.196272 },
	{ lat: 25.061739, lng: 55.197989 },
	{ lat: 25.066171, lng: 55.201679 },
	{ lat: 25.071769, lng: 55.206572 },
	{ lat: 25.074801, lng: 55.209490 },
	{ lat: 25.075034, lng: 55.210434 },
	{ lat: 25.074956, lng: 55.212151 },
	{ lat: 25.074334, lng: 55.213009 },
	{ lat: 25.070680, lng: 55.216700 },
	{ lat: 25.066715, lng: 55.219275 },
	{ lat: 25.063139, lng: 55.220391 },
	{ lat: 25.057696, lng: 55.221678 },
	{ lat: 25.055597, lng: 55.222107 },
	{ lat: 25.054508, lng: 55.221678 },
	{ lat: 25.052331, lng: 55.220820 },
	{ lat: 25.051009, lng: 55.220305 },
	{ lat: 25.047044, lng: 55.212580 },
	{ lat: 25.045800, lng: 55.208288 },
	{ lat: 25.044944, lng: 55.203139 },
	{ lat: 25.045255, lng: 55.201079 },
	{ lat: 25.046111, lng: 55.199620 },
	{ lat: 25.047433, lng: 55.197731 },
]

export const district_one = [
	{ lat: 25.167647, lng: 55.267112 },
	{ lat: 25.161277, lng: 55.274150 },
	{ lat: 25.154907, lng: 55.279471 },
	{ lat: 25.152887, lng: 55.281188 },
	{ lat: 25.144962, lng: 55.273120 },
	{ lat: 25.139368, lng: 55.281703 },
	{ lat: 25.140922, lng: 55.302646 },
	{ lat: 25.141699, lng: 55.307967 },
	{ lat: 25.148691, lng: 55.307281 },
	{ lat: 25.155217, lng: 55.313460 },
	{ lat: 25.155217, lng: 55.317924 },
	{ lat: 25.145739, lng: 55.322902 },
	{ lat: 25.157393, lng: 55.345831 },
	{ lat: 25.169454, lng: 55.325881 },
	{ lat: 25.170386, lng: 55.332575 },
	{ lat: 25.175668, lng: 55.333605 },
	{ lat: 25.179863, lng: 55.329142 },
	{ lat: 25.184713, lng: 55.329441 },
	{ lat: 25.183237, lng: 55.323519 },
	{ lat: 25.181373, lng: 55.318541 },
	{ lat: 25.179275, lng: 55.317167 },
	{ lat: 25.178887, lng: 55.315708 },
	{ lat: 25.180052, lng: 55.312704 },
	{ lat: 25.180674, lng: 55.312103 },
	{ lat: 25.182326, lng: 55.301131 },
	{ lat: 25.182948, lng: 55.295123 },
	{ lat: 25.181705, lng: 55.287398 },
	{ lat: 25.180462, lng: 55.282077 },
	{ lat: 25.177821, lng: 55.277957 },
	{ lat: 25.172850, lng: 55.271777 },
	{ lat: 25.168655, lng: 55.268001 },
]

export const district_11 = [
	{ lat: 25.135739, lng: 55.350578 },
	{ lat: 25.120042, lng: 55.364826 },
	{ lat: 25.110872, lng: 55.346287 },
	{ lat: 25.119576, lng: 55.339420 },
	{ lat: 25.121286, lng: 55.339077 },
	{ lat: 25.126725, lng: 55.335815 },
	{ lat: 25.129367, lng: 55.337875 },
	{ lat: 25.135118, lng: 55.349892 },
]

export const jumeirah_heights = [
	{ lat: 25.040671, lng: 55.171786 },
	{ lat: 25.043774, lng: 55.168930 },
	{ lat: 25.046263, lng: 55.171076 },
	{ lat: 25.048751, lng: 55.171848 },
	{ lat: 25.050772, lng: 55.170990 },
	{ lat: 25.053261, lng: 55.170217 },
	{ lat: 25.057382, lng: 55.163522 },
	{ lat: 25.065805, lng: 55.152583 },
	{ lat: 25.068604, lng: 55.148635 },
	{ lat: 25.070547, lng: 55.146489 },
	{ lat: 25.066038, lng: 55.142026 },
	{ lat: 25.063939, lng: 55.139709 },
	{ lat: 25.061451, lng: 55.142284 },
	{ lat: 25.060285, lng: 55.142713 },
	{ lat: 25.059585, lng: 55.141854 },
	{ lat: 25.059196, lng: 55.141340 },
	{ lat: 25.057952, lng: 55.139194 },
	{ lat: 25.053598, lng: 55.141254 },
	{ lat: 25.051421, lng: 55.143485 },
	{ lat: 25.045822, lng: 55.149322 },
	{ lat: 25.042090, lng: 55.154214 },
	{ lat: 25.040223, lng: 55.156532 },
	{ lat: 25.033613, lng: 55.166059 },
	{ lat: 25.040301, lng: 55.172153 },
]

export const international_city = [
	{ lat: 25.178557, lng: 55.401254 },
	{ lat: 25.177004, lng: 55.397391 },
	{ lat: 25.166672, lng: 55.393615 },
	{ lat: 25.160923, lng: 55.392928 },
	{ lat: 25.158670, lng: 55.394731 },
	{ lat: 25.157272, lng: 55.397735 },
	{ lat: 25.154320, lng: 55.404773 },
	{ lat: 25.152144, lng: 55.409837 },
	{ lat: 25.150140, lng: 55.417484 },
	{ lat: 25.152704, lng: 55.421260 },
	{ lat: 25.153558, lng: 55.423063 },
	{ lat: 25.172747, lng: 55.427268 },
	{ lat: 25.175155, lng: 55.425208 },
	{ lat: 25.177330, lng: 55.411647 },
	{ lat: 25.178748, lng: 55.402332 },
]

export const bus_road_1_stops = [
	{
		name: 'Jumeirah Golf Estates',
		coords: [25.017772955820742, 55.163710995850835]
	},
	{
		name: 'Dubai Investment Park',
		coords: [25.005692940092054, 55.15562540748922]
	},
	{
		name: 'EXPO 2020',
		coords: [24.963794660843785, 55.145743253521125]
	},
	{
		name: 'The Gardens',
		coords: [25.043822100415827, 55.13440958050732]
	},
	{
		name: 'Discovery Gardens',
		coords: [25.03483681181547, 55.14495236886811]
	},
	{
		name: 'Al Furjan',
		coords: [25.030650944415378, 55.152206809342985]
	},
	{
		name: 'UAE Exchange',
		coords: [24.977649633464985, 55.09102075352159]
	},
	{
		name: 'Danube Metro Station',
		coords: [25.001348589696118, 55.0957278502079]
	},
	{
		name: 'Energy',
		coords: [25.026330934737505, 55.10134582654013]
	},
	{
		name: 'Ibn Battuta',
		coords: [25.044733360058594, 55.11657326701567]
	},
	{
		name: 'Jabal Ali',
		coords: [25.057845496973602, 55.126971895852265]
	},
	{
		name: 'DMCC',
		coords: [25.071048555908966, 55.13865082283596]
	},
	{
		name: 'Sobha Realty',
		coords: [25.0800957754182, 55.147451411197466]
	},
	{
		name: 'Al Khail',
		coords: [25.089000059626148, 55.15813554003375]
	},
	{
		name: 'Dubai Internet City',
		coords: [25.102287812558263, 55.17369264003425]
	},
	{
		name: 'Mashreq ',
		coords: [25.114975292273478, 55.19091421200822]
	},
	{
		name: 'Mall of the Emirates',
		coords: [25.121481143747527, 55.20037220934596]
	},
	{
		name: 'Equiti ',
		coords: [25.126602722408403, 55.20745065352676]
	},
	{
		name: 'Onpassive Metro Station',
		coords: [25.156029981600174, 55.22848955999912]
	},
	{
		name: 'Business Bay',
		coords: [25.19125511282233, 55.26032592469287]
	},
	{
		name: 'Burj Khalifa/Dubai Mall',
		coords: [25.20161164536886, 55.269464895857105]
	},
	{
		name: 'Financial Centre',
		coords: [25.21173765260232, 55.274728195857286]
	},
	{
		name: 'Emirates Towers',
		coords: [25.21747267299612, 55.27992019585751]
	},
	{
		name: 'World Trade Centre',
		coords: [25.225066121472754, 55.28506278236618]
	},
	{
		name: 'Max',
		coords: [25.23386144638582, 55.29210791421783]
	},
	{
		name: 'ADCB (Abu Dhabi Commercial Bank)',
		coords: [25.244757998920846, 55.29814015167797]
	},
	{
		name: 'Al Rigga',
		coords: [25.263901698901385, 55.323860969621656]
	},
	{
		name: 'Deira City Centre',
		coords: [25.255273147765102, 55.33030979585883]
	},
	{
		name: 'GGICO',
		coords: [25.24991917156743, 55.33931664469571]
	},
	{
		name: 'Airport Terminal 1',
		coords: [25.248430184923514, 55.352369759510516]
	},
	{
		name: 'Airport Terminal 3',
		coords: [25.245316076900806, 55.35965114003925]
	},
	{
		name: 'Emirates',
		coords: [25.241243166840587, 55.36565485353067]
	},
	{
		name: 'Centrepoint',
		coords: [25.22986285623351, 55.39190106366855]
	},
]

export const bus_road_1 = [
	{ lat: 25.22986285623351, lng: 55.39190106366855 },
	{ lat: 25.230582, lng: 55.391541 },
	{ lat: 25.235357, lng: 55.380130 },
	{ lat: 25.235240, lng: 55.378757 },
	{ lat: 25.235395, lng: 55.377384 },
	{ lat: 25.236172, lng: 55.375667 },
	{ lat: 25.240054, lng: 55.367556 },
	{ lat: 25.242033, lng: 55.363651 },
	{ lat: 25.243353, lng: 55.362706 },
	{ lat: 25.246448, lng: 55.356983 },
	{ lat: 25.246836, lng: 55.356125 },
	{ lat: 25.247185, lng: 55.354838 },
	{ lat: 25.247923, lng: 55.353207 },
	{ lat: 25.249801, lng: 55.349656 },
	{ lat: 25.249801, lng: 55.348883 },
	{ lat: 25.249917, lng: 55.347767 },
	{ lat: 25.250150, lng: 55.346995 },
	{ lat: 25.250344, lng: 55.346394 },
	{ lat: 25.250344, lng: 55.345235 },
	{ lat: 25.249956, lng: 55.343862 },
	{ lat: 25.249723, lng: 55.341806 },
	{ lat: 25.249412, lng: 55.337557 },
	{ lat: 25.249166, lng: 55.335971 },
	{ lat: 25.249205, lng: 55.334598 },
	{ lat: 25.249710, lng: 55.333139 },
	{ lat: 25.250020, lng: 55.332195 },
	{ lat: 25.251058, lng: 55.329851 },
	{ lat: 25.251388, lng: 55.329765 },
	{ lat: 25.251970, lng: 55.329765 },
	{ lat: 25.252591, lng: 55.329894 },
	{ lat: 25.253270, lng: 55.330087 },
	{ lat: 25.254812, lng: 55.330375 },
	{ lat: 25.256597, lng: 55.330525 },
	{ lat: 25.257510, lng: 55.330332 },
	{ lat: 25.258587, lng: 55.330161 },
	{ lat: 25.259364, lng: 55.329967 },
	{ lat: 25.260120, lng: 55.329302 },
	{ lat: 25.260703, lng: 55.328701 },
	{ lat: 25.261440, lng: 55.327736 },
	{ lat: 25.262158, lng: 55.326384 },
	{ lat: 25.265591, lng: 55.319375 },
	{ lat: 25.266895, lng: 55.316714 },
	{ lat: 25.267050, lng: 55.316306 },
	{ lat: 25.267050, lng: 55.315856 },
	{ lat: 25.266856, lng: 55.315469 },
	{ lat: 25.266643, lng: 55.315019 },
	{ lat: 25.266197, lng: 55.313238 },
	{ lat: 25.265995, lng: 55.313001 },
	{ lat: 25.265200, lng: 55.312679 },
	{ lat: 25.264637, lng: 55.312486 },
	{ lat: 25.263725, lng: 55.311628 },
	{ lat: 25.263240, lng: 55.311199 },
	{ lat: 25.259876, lng: 55.308473 },
	{ lat: 25.258357, lng: 55.306651 },
	{ lat: 25.254174, lng: 55.304072 },
	{ lat: 25.249732, lng: 55.301422 },
	{ lat: 25.241429, lng: 55.296599 },
	{ lat: 25.240287, lng: 55.295960 },
	{ lat: 25.236890, lng: 55.294050 },
	{ lat: 25.231645, lng: 55.291064 },
	{ lat: 25.229075, lng: 55.289423 },
	{ lat: 25.228726, lng: 55.288844 },
	{ lat: 25.228357, lng: 55.288243 },
	{ lat: 25.227664, lng: 55.287058 },
	{ lat: 25.225994, lng: 55.285771 },
	{ lat: 25.223344, lng: 55.284094 },
	{ lat: 25.220894, lng: 55.282392 },
	{ lat: 25.219224, lng: 55.281319 },
	{ lat: 25.216785, lng: 55.279579 },
	{ lat: 25.209364, lng: 55.274538 },
	{ lat: 25.206069, lng: 55.272382 },
	{ lat: 25.204807, lng: 55.272039 },
	{ lat: 25.203700, lng: 55.271546 },
	{ lat: 25.202962, lng: 55.270966 },
	{ lat: 25.200727, lng: 55.268944 },
	{ lat: 25.197980, lng: 55.266870 },
	{ lat: 25.196815, lng: 55.265582 },
	{ lat: 25.195941, lng: 55.264702 },
	{ lat: 25.193531, lng: 55.262527 },
	{ lat: 25.191781, lng: 55.260897 },
	{ lat: 25.187894, lng: 55.257328 },
	{ lat: 25.183093, lng: 55.252783 },
	{ lat: 25.178237, lng: 55.248473 },
	{ lat: 25.174999, lng: 55.245402 },
	{ lat: 25.172450, lng: 55.243047 },
	{ lat: 25.169399, lng: 55.240202 },
	{ lat: 25.165686, lng: 55.236722 },
	{ lat: 25.163641, lng: 55.234887 },
	{ lat: 25.149696, lng: 55.224490 },
	{ lat: 25.142470, lng: 55.220113 },
	{ lat: 25.130353, lng: 55.212303 },
	{ lat: 25.125458, lng: 55.205952 },
	{ lat: 25.115890, lng: 55.192295 },
	{ lat: 25.099846, lng: 55.170892 },
	{ lat: 25.088031, lng: 55.157073 },
	{ lat: 25.077442, lng: 55.144375 },
	{ lat: 25.070507, lng: 55.138293 },
	{ lat: 25.062651, lng: 55.131286 },
	{ lat: 25.056173, lng: 55.125440 },
	{ lat: 25.054035, lng: 55.125355 },
	{ lat: 25.052635, lng: 55.125741 },
	{ lat: 25.049214, lng: 55.128144 },
	{ lat: 25.047783, lng: 55.129185 },
	{ lat: 25.046539, lng: 55.130902 },
	{ lat: 25.045373, lng: 55.132661 },
	{ lat: 25.044206, lng: 55.134335 },
	{ lat: 25.042170, lng: 55.137254 },
	{ lat: 25.041101, lng: 55.138627 },
	{ lat: 25.039851, lng: 55.140012 },
	{ lat: 25.036462, lng: 55.142913 },
	{ lat: 25.034612, lng: 55.146093 },
	{ lat: 25.033406, lng: 55.148496 },
	{ lat: 25.028662, lng: 55.154547 },
	{ lat: 25.025824, lng: 55.158881 },
	{ lat: 25.023566, lng: 55.161735 },
	{ lat: 25.022735, lng: 55.162893 },
	{ lat: 25.021530, lng: 55.163580 },
	{ lat: 25.020519, lng: 55.163365 },
	{ lat: 25.019041, lng: 55.163365 },
	{ lat: 25.018263, lng: 55.163322 },
	{ lat: 25.015036, lng: 55.162593 },
	{ lat: 25.013830, lng: 55.161692 },
	{ lat: 25.012974, lng: 55.160619 },
	{ lat: 25.012002, lng: 55.159417 },
	{ lat: 25.010602, lng: 55.158216 },
	{ lat: 25.007306, lng: 55.156448 },
	{ lat: 25.003922, lng: 55.155161 },
	{ lat: 25.000718, lng: 55.154796 },
	{ lat: 24.997378, lng: 55.154383 },
	{ lat: 24.995160, lng: 55.153868 },
	{ lat: 24.990380, lng: 55.151446 },
	{ lat: 24.987405, lng: 55.149969 },
	{ lat: 24.984176, lng: 55.149239 },
	{ lat: 24.982370, lng: 55.148473 },
	{ lat: 24.979679, lng: 55.146710 },
	{ lat: 24.978056, lng: 55.146031 },
	{ lat: 24.976734, lng: 55.145859 },
	{ lat: 24.975333, lng: 55.145645 },
	{ lat: 24.973894, lng: 55.146074 },
	{ lat: 24.971910, lng: 55.146718 },
	{ lat: 24.968007, lng: 55.147018 },
	{ lat: 24.965324, lng: 55.147224 },
	{ lat: 24.963612, lng: 55.146108 },
	{ lat: 24.963794660843785, lng: 55.145743253521125 },
]

export const bus_road_1_2 = [
	{ lat: 25.062651, lng: 55.131286 },
	{ lat: 25.056173, lng: 55.125440 },
	{ lat: 25.031917, lng: 55.103867 },
	{ lat: 25.026706, lng: 55.100606 },
	{ lat: 25.019240, lng: 55.097945 },
	{ lat: 25.012551, lng: 55.096743 },
	{ lat: 25.001025, lng: 55.094900 },
	{ lat: 24.977649633464985, lng: 55.09102075352159 },
]

export const bus_road_2_stops = [
	{
		name: 'Creek',
		coords: [25.21911154590219, 55.339003764669876]
	},
	{
		name: 'Al Jadaf',
		coords: [25.22515089255185, 55.33417663691971]
	},
	{
		name: 'Union',
		coords: [25.266530126430993, 55.314945277398536]
	},
	{
		name: 'Salah Al Din',
		coords: [25.270334820346424, 55.321551767608135]
	},
	{
		name: 'Abu Baker Al Siddique',
		coords: [25.271002236794967, 55.333178920431905]
	},
	{
		name: 'Abu Hail',
		coords: [25.275327905397, 55.34693135411595]
	},
	{
		name: 'Al Qiyadah',
		coords: [25.277820508372365, 55.353175935070524]
	},
	{
		name: 'Stadium',
		coords: [25.27790202973908, 55.362054872113475]
	},
	{
		name: 'Al Nahda',
		coords: [25.2734901152996, 55.37033565966881]
	},
	{
		name: 'Dubai Airport Free Zone',
		coords: [25.270235042038657, 55.375758255966645]
	},
	{
		name: 'Al Qusais',
		coords: [25.262859518615688, 55.38787005041344]
	},
	{
		name: 'Etisalat',
		coords: [25.25499004488136, 55.40154403877181]
	},
	{
		name: 'Baniyas Square',
		coords: [25.26947482393235, 55.3084392866535]
	},
	{
		name: 'Gold Souq',
		coords: [25.27632651741819, 55.30216073692151]
	},
	{
		name: 'Al Ras',
		coords: [25.268689727524773, 55.293581123428886]
	},
	{
		name: 'Al Gubaiba',
		coords: [25.26519723111498, 55.28977359644381]
	},
	{
		name: 'Sharaf DG',
		coords: [25.25835861473298, 55.29780418718855]
	},
	{
		name: 'Burjuman',
		coords: [25.25488751950197, 55.304156762589955]
	},
	{
		name: 'Oud Metha',
		coords: [25.243839684002328, 55.31634990623347]
	},
	{
		name: 'Dubai Healthcare City',
		coords: [25.231084483076582, 55.323201190889534]
	},
]

export const bus_road_2 = [
	{ lat: 25.254658, lng: 55.401077 },
	{ lat: 25.259950, lng: 55.391793 },
	{ lat: 25.264907, lng: 55.383409 },
	{ lat: 25.267535, lng: 55.378672 },
	{ lat: 25.278960, lng: 55.359575 },
	{ lat: 25.280111, lng: 55.357317 },
	{ lat: 25.280538, lng: 55.356674 },
	{ lat: 25.280926, lng: 55.355901 },
	{ lat: 25.280732, lng: 55.355172 },
	{ lat: 25.280305, lng: 55.354614 },
	{ lat: 25.275881, lng: 55.349721 },
	{ lat: 25.275532, lng: 55.349206 },
	{ lat: 25.275765, lng: 55.348305 },
	{ lat: 25.275765, lng: 55.347619 },
	{ lat: 25.272181, lng: 55.337988 },
	{ lat: 25.271431, lng: 55.334987 },
	{ lat: 25.270894, lng: 55.332813 },
	{ lat: 25.270705, lng: 55.330683 },
	{ lat: 25.270453, lng: 55.323346 },
	{ lat: 25.270410, lng: 55.320410 },
	{ lat: 25.270483, lng: 55.319525 },
	{ lat: 25.270434, lng: 55.318173 },
	{ lat: 25.270356, lng: 55.317712 },
	{ lat: 25.270259, lng: 55.317379 },
	{ lat: 25.270269, lng: 55.317079 },
	{ lat: 25.270424, lng: 55.316553 },
	{ lat: 25.270424, lng: 55.316178 },
	{ lat: 25.270124, lng: 55.315952 },
	{ lat: 25.269797, lng: 55.316050 },
	{ lat: 25.269603, lng: 55.316329 },
	{ lat: 25.268788, lng: 55.316072 },
	{ lat: 25.267740, lng: 55.316029 },
	{ lat: 25.266614, lng: 55.315471 },
	{ lat: 25.266595, lng: 55.314827 },
	{ lat: 25.265822, lng: 55.312365 },
	{ lat: 25.265677, lng: 55.311882 },
	{ lat: 25.265774, lng: 55.311292 },
	{ lat: 25.265929, lng: 55.310938 },
	{ lat: 25.266750, lng: 55.309402 },
	{ lat: 25.267565, lng: 55.308501 },
	{ lat: 25.268303, lng: 55.307857 },
	{ lat: 25.269894, lng: 55.307578 },
	{ lat: 25.271194, lng: 55.307536 },
	{ lat: 25.271961, lng: 55.307321 },
	{ lat: 25.273383, lng: 55.306471 },
	{ lat: 25.276161, lng: 55.305165 },
	{ lat: 25.276588, lng: 55.304650 },
	{ lat: 25.276704, lng: 55.304243 },
	{ lat: 25.275986, lng: 55.302934 },
	{ lat: 25.274732, lng: 55.300158 },
	{ lat: 25.273859, lng: 55.297970 },
	{ lat: 25.273097, lng: 55.295718 },
	{ lat: 25.271853, lng: 55.292403 },
	{ lat: 25.271038, lng: 55.290837 },
	{ lat: 25.269940, lng: 55.288902 },
	{ lat: 25.268446, lng: 55.288151 },
	{ lat: 25.262965, lng: 55.284956 },
	{ lat: 25.261277, lng: 55.284290 },
	{ lat: 25.260481, lng: 55.284312 },
	{ lat: 25.260248, lng: 55.284891 },
	{ lat: 25.259724, lng: 55.286715 },
	{ lat: 25.259240, lng: 55.290936 },
	{ lat: 25.259551, lng: 55.292739 },
	{ lat: 25.259609, lng: 55.294048 },
	{ lat: 25.259279, lng: 55.295314 },
	{ lat: 25.258541, lng: 55.296623 },
	{ lat: 25.257972, lng: 55.297882 },
	{ lat: 25.255120, lng: 55.303541 },
	{ lat: 25.253878, lng: 55.306073 },
	{ lat: 25.253063, lng: 55.308348 },
	{ lat: 25.251285, lng: 55.311092 },
	{ lat: 25.247588, lng: 55.314160 },
	{ lat: 25.244677, lng: 55.315405 },
	{ lat: 25.241028, lng: 55.316435 },
	{ lat: 25.236306, lng: 55.317064 },
	{ lat: 25.233376, lng: 55.317121 },
	{ lat: 25.232289, lng: 55.316949 },
	{ lat: 25.231745, lng: 55.317250 },
	{ lat: 25.231270, lng: 55.319392 },
	{ lat: 25.230760, lng: 55.323572 },
	{ lat: 25.230411, lng: 55.325263 },
	{ lat: 25.230489, lng: 55.326036 },
	{ lat: 25.231071, lng: 55.326765 },
	{ lat: 25.231479, lng: 55.327430 },
	{ lat: 25.231479, lng: 55.327795 },
	{ lat: 25.231110, lng: 55.328095 },
	{ lat: 25.230217, lng: 55.328653 },
	{ lat: 25.225889, lng: 55.332708 },
	{ lat: 25.222805, lng: 55.335530 },
	{ lat: 25.219606, lng: 55.338295 },
	{ lat: 25.219074, lng: 55.338907 },
]

export const bus_road_3_stops = [
	{
		name: 'Jumeirah Beach Residence 1',
		coords: [25.080091169751416, 55.13871578242958]
	},
	{
		name: 'Jumeirah Beach Residence 2',
		coords: [25.073957672591433, 55.13306615265066]
	},
	{
		name: 'Jumeirah Lakes Towers',
		coords: [25.071649901021395, 55.13817214855567]
	},
	{
		name: 'Dubai Marina Mall',
		coords: [25.076347571191214, 55.14240193506357]
	},
	{
		name: 'Dubai Marina',
		coords: [25.081354584089837, 55.14778370128216]
	},
	{
		name: 'Marina Towers',
		coords: [25.086905878882742, 55.15011149342247]
	},
	{
		name: 'Mina Seyahi',
		coords: [25.091212855120702, 55.148631481629785]
	},
	{
		name: 'Media City',
		coords: [25.094717002735855, 55.151895592200376]
	},
	{
		name: 'Palm Jumeirah',
		coords: [25.09849244181908, 55.15615761972086]
	},
	{
		name: 'Knowledge Village',
		coords: [25.10442724941909, 55.161490328758106]
	},
	{
		name: 'Al Sufouh',
		coords: [25.108268581656745, 55.16511613825428]
	},
]

export const bus_road_3 = [
	{ lat: 25.108489, lng: 55.165148 },
	{ lat: 25.101261, lng: 55.158196 },
	{ lat: 25.096254, lng: 55.153520 },
	{ lat: 25.090474, lng: 55.147593 },
	{ lat: 25.089330, lng: 55.146736 },
	{ lat: 25.088086, lng: 55.148023 },
	{ lat: 25.086959, lng: 55.149482 },
	{ lat: 25.086104, lng: 55.150040 },
	{ lat: 25.085171, lng: 55.150255 },
	{ lat: 25.084238, lng: 55.150169 },
	{ lat: 25.083344, lng: 55.149611 },
	{ lat: 25.081899, lng: 55.148006 },
	{ lat: 25.080539, lng: 55.146247 },
	{ lat: 25.078532, lng: 55.143941 },
	{ lat: 25.077889, lng: 55.143212 },
	{ lat: 25.080546, lng: 55.140338 },
	{ lat: 25.079143, lng: 55.137213 },
	{ lat: 25.077238, lng: 55.136312 },
	{ lat: 25.076344, lng: 55.135883 },
	{ lat: 25.075411, lng: 55.133050 },
	{ lat: 25.072963, lng: 55.131891 },
	{ lat: 25.072107, lng: 55.131419 },
	{ lat: 25.069231, lng: 55.135410 },
	{ lat: 25.068842, lng: 55.135754 },
	{ lat: 25.077889, lng: 55.143212 },
	// { lat: 25.061285, lng: 55.129177 },
	// { lat: 25.054191, lng: 55.122982 },
	// { lat: 25.049881, lng: 55.119383 },
	// { lat: 25.036075, lng: 55.107158 },
	// { lat: 25.031917, lng: 55.103867 },
	// { lat: 25.026706, lng: 55.100606 },
	// { lat: 25.019240, lng: 55.097945 },
	// { lat: 25.012551, lng: 55.096743 },
	// { lat: 25.001025, lng: 55.094900 },
	// { lat: 24.977649633464985, lng: 55.09102075352159 },
]



export const canal_front_residences = [
	{ lat: 25.187160, lng: 55.240530 },
	{ lat: 25.197926, lng: 55.247775 },
	{ lat: 25.191272, lng: 55.260286 },
	{ lat: 25.180763, lng: 55.249477 },
	{ lat: 25.186814, lng: 55.240652 },
]
